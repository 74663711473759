import { ExternalRateIndex } from '@dltru/dfa-models'
import { openMessage } from '@dltru/dfa-ui'
import { StrictEffect } from 'redux-saga/effects'
import { all, call, put, takeLatest } from 'typed-redux-saga'

import { loadDfaRates, setRates, updateDfaRates } from '@store/dfa/rates/index'

import api from '@services/api'

function* fetchRate(index: ExternalRateIndex) {
    const { data } = yield* call(api.lib.getExternalRates, index)
    if (data?.item) {
        yield put(setRates({ [data.item.index]: data.item.rate }))
    }
}

function* getRates() {
    try {
        yield* put(updateDfaRates({ isLoading: true }))
        yield* all(
            Object.values(ExternalRateIndex).map((index) => {
                return call(fetchRate, index)
            }),
        )
        yield* put(updateDfaRates({ isLoading: false }))
    } catch (error) {
        yield* put(updateDfaRates({ isLoading: false, error }))
        openMessage({ type: 'error', message: 'Возникли проблемы получении курса ' })
    }
}

export function* watchDfaRates(): Generator<StrictEffect> {
    yield* takeLatest(loadDfaRates.type, getRates)
}
