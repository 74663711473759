import { ExternalRateIndex } from '@dltru/dfa-models'
import { createAction, createSlice } from '@reduxjs/toolkit'

interface IInitialState {
    isLoading: boolean
    data: Record<ExternalRateIndex, number>
    error?: string
}

const initialState: IInitialState = {
    error: '',
    isLoading: false,
    data: {} as Record<ExternalRateIndex, number>,
}

const dfaRatesSlice = createSlice({
    name: 'dfaRates',
    initialState,
    reducers: {
        setRates: (state, action) => {
            state.data = { ...state.data, ...action.payload }
        },
        updateDfaRates: (state, action) => {
            return { ...state, ...action.payload }
        },
    },
})

export const loadDfaRates = createAction('dfaRates/loadRates')

export const { setRates, updateDfaRates } = dfaRatesSlice.actions
export default dfaRatesSlice.reducer
