import { DecisionMakingType, DfaStatusEnum, SettlementsType } from '@dltru/dfa-models'
import { DEFAULT_PER_PAGE, UploadFile } from '@dltru/dfa-ui'

import {
    DfaDealDetailState,
    IDfaDealsListState,
    IDfaDetailsState,
    IDfaOwnersListState,
    IOwnershipTransferState,
} from './types'

export const initialState = {
    attachments: [],
    emitter_id: '',
    status: DfaStatusEnum.draft,
    ois_info: '',
    title: '',
    operator_id: '',
    ticker_symbol: '',
    document: [] as UploadFile[],
    decision: '',
    issue_restriction: '',
    error: '',
    isLoading: false,
    errorLink: '',
    isLoadingLink: false,
    emitter_full_name: '',
    issue_settlements_type: SettlementsType.platform,
    decision_making_type: DecisionMakingType.platform,
    issue_restrictions: '',
    issue_conditions: '',
    couponeTableCurrentPage: 1,
    issue_fee_amount: 0,
} as unknown as IDfaDetailsState

export const initialStateDealsList = {
    isLoading: false,
    filters: {},
    sort: {},
    range: {},
    data: [],
    error: '',
} as IDfaDealsListState

export const initialStateDealDetail = {
    id: 0,
    asset_id: 0,
    order_id: '',
    emitter_id: '',
    user_id: '',
    price_per_dfa: 0,
    amount_dfa: 0,
    executed_at: 0,
    isLoading: false,
    error: '',
} as DfaDealDetailState

export const initialStateOwnersList = {
    isLoading: false,
    filters: {},
    sort: {},
    page: 0,
    limit: DEFAULT_PER_PAGE,
    data: [],
    error: '',
} as IDfaOwnersListState

export const initialStateOwnershipTransfer = {
    incoming: null,
    outgoing: null,
    accepting: null,
} as IOwnershipTransferState
