import { ExternalRateIndex } from '@dltru/dfa-models'

import IAppState from '@store/states'

const selectDfaRates = (state: IAppState) => state.dfaRates.data
const selectDfaRate = (rateIndex: ExternalRateIndex) => (state: IAppState) => {
    return state.dfaRates.data[rateIndex]
}

export const dfaRatesSelector = {
    selectDfaRates,
    selectDfaRate,
}
