import { TransferOrderStatus } from '@dltru/dfa-models'
import { closeMessage, errorTranslates, openMessage } from '@dltru/dfa-ui'
import { isAxiosError } from '@utils/typeGuard'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import { authSelector } from '@store/auth/selectors'
import { getCalculateFeeHelper, signCustomTransaction, signData } from '@store/helper'

import api from '@services/api'

import { getDfaBalanceByIdHelper, getDfaByIdHelper } from '../details/helpers'
import '../ownershipTransfer'
import {
    acceptDFAOwnershipTransfer,
    checkLimitRecipientTransferAction,
    createDFAOwnershipTransferRequestActionPrefly,
    createDFAOwnershipTransferRequestAction,
    dfaOwnershipTransferSlice,
    executeDFATransfer,
    getAcceptorDFA,
    getDFAOwnershipTransfer,
    getOfferentDFA,
    getTransferDeals,
    getTransferFee,
    setAccepterOffer,
    setDFAOwnershipTransfer,
    setDFAOwnershipTransferError,
    setDFAOwnershipTransferLoading,
    setFee,
    setOfferentOffer,
    setTransferDeals,
    setTransferDealsIsLoading,
    transferCancelByOwner,
    transferCancelByRecipient,
    acceptDFAOwnershipTransferPrefly,
    executeDFATransferPrefly,
    transferCancelByOwnerPrefly,
} from './index'
import { dfaTransferSelector } from './selectors'
import { clearDataTransactionDetails, setIsLoadingTransactionDetails, setItemsTransactionDetails } from '@store/sign'
import { transactionDetailsSelectors } from '@store/sign/selectors'

function* createDFAOwnershipTransferRequestPrefly({
    payload,
}: ReturnType<typeof createDFAOwnershipTransferRequestActionPrefly>) {
    try {
        openMessage({
            type: 'loading',
            key: 'prefly',
            message: 'Формирование транзакции',
        })

        yield* put(setIsLoadingTransactionDetails(true))
        const { error, data } = yield api.lib.createDFAOwnershipTransferRequestV3(payload.data)
        if (error || data?.error) {
            throw error || data?.error
        }
        yield* put(setItemsTransactionDetails(data.item))
        closeMessage('prefly')
        yield* put(setIsLoadingTransactionDetails(false))
    } catch (error) {
        yield* put(setIsLoadingTransactionDetails(false))
        closeMessage('prefly')
        openMessage({
            type: 'error',
            message: 'Ошибка формирования транзакции',
        })

    }
}

function* createDFAOwnershipTransferRequest({
    payload,
}: ReturnType<typeof createDFAOwnershipTransferRequestAction>) {
    yield* put(setDFAOwnershipTransferLoading(true))
    try {
        const data = yield* select(transactionDetailsSelectors.selectItems)

        if (data) {
            const dataForSign = {
                ...payload.data,
                transaction: data
              }
              yield* signCustomTransaction(
                payload.data.skid,
                dataForSign,
                api.lib.putDFAOwnershipTransferRequestV3,
              )
            payload?.callback?.()
            yield* put(clearDataTransactionDetails())
            openMessage({
                type: 'success',
                message: 'Запрос на создание оферты успешно отправлен',
            })
        }
    } catch (error: any) {
        yield* put(setDFAOwnershipTransferError(error.message))
        yield* put(clearDataTransactionDetails())
        openMessage({
            type: 'error',
            message: 'Возникли ошибки при отправке запроса на создание оферты',
        })
    } finally {
        yield* put(setDFAOwnershipTransferLoading(false))
    }
}

function* loadDFAOwnershipTransfer(action: ReturnType<typeof getDFAOwnershipTransfer>) {
    yield* put(setDFAOwnershipTransferLoading(true))

    try {
        const uuid = yield* select(authSelector.selectUserUid)
        const { data, error } = yield api.lib.getDFAOwnershipTransferByGlobalIdRequest(
            action.payload.globalId,
        )

        if (error) {
            throw new Error(error)
        }
        if (data?.items) {
            let offer = data.items.find((item) => !item.is_reply)
            if (data.items.length > 1) {
                offer = data.items.find((item) => {
                    if (item.seller_id === uuid) {
                        return true
                    }
                    return false
                })
            }

            const rootOffer = data.items.find((item) => !item.is_reply)
            const replyOffer = data.items.find((item) => item.is_reply)

            offer.show_transfer_button =
                offer.status === TransferOrderStatus.accepted &&
                Boolean(offer.seller_asset_data.asset_id)

            let inProgressStatus = null

            if (rootOffer && replyOffer) {
                inProgressStatus =
                    (rootOffer?.status === TransferOrderStatus.executed &&
                        replyOffer.status !== TransferOrderStatus.executed) ||
                    (rootOffer?.status !== TransferOrderStatus.executed &&
                        replyOffer.status === TransferOrderStatus.executed)
                        ? TransferOrderStatus.ready
                        : null

                /**
                 * факт наличия 2 оферт найденных по глобал айди уже говорит о том что невозможно отменить ни первую ни вторую
                 */

                rootOffer.noCancel = true
            }

            offer.status = inProgressStatus || offer.status

            if (rootOffer) {
                rootOffer.uuid = offer.uuid
                rootOffer.status = offer.status
                rootOffer.seller_id_button = offer.seller_id
                rootOffer.show_transfer_button = offer.show_transfer_button
            }

            yield* put(setDFAOwnershipTransfer({ data: rootOffer || offer }))
        }
    } catch (error) {
        yield* put(setDFAOwnershipTransferError(error))
    }
}

function* acceptOwnershipTransferPrefly(action: ReturnType<typeof acceptDFAOwnershipTransfer>) {
    try {
        openMessage({
          type: 'loading',
          key: 'prefly',
          message: 'Формирование транзакции',
        });
      
        yield * put(setIsLoadingTransactionDetails(true));
        const { data, error } = yield api.lib.acceptDFAOwnershipTransferRequestV2(action.payload)

        if (error) {
            throw new Error(error)
        }
        yield * put(setItemsTransactionDetails(data));
        closeMessage('prefly');
        yield * put(setIsLoadingTransactionDetails(false));
      } catch (error) {
        yield * put(setIsLoadingTransactionDetails(false));
        closeMessage('prefly');
        openMessage({
          type: 'error',
          message: 'Ошибка формирования транзакции',
        });
      }
}

function* acceptOwnershipTransfer(action: ReturnType<typeof acceptDFAOwnershipTransfer>) {
    yield* put(setDFAOwnershipTransferLoading(true))
    try {
        const data = yield* select(transactionDetailsSelectors.selectItems)

        if (data?.item) {
            yield* signData(data?.item?.status, action.payload.skid, data)
            openMessage({
                type: 'success',
                message: 'Запрос на акцепт оферты успешно отправлен',
            })
        }
        yield* put(clearDataTransactionDetails())
    } catch (error) {
        yield* put(clearDataTransactionDetails())
        yield* put(setDFAOwnershipTransferError(error))
        openMessage({
            type: 'error',
            message: 'Ошибка отправки запроса на акцепт оферты',
        })
    }
}

function* checkLimitRecipientTransfer({
    payload,
}: ReturnType<typeof checkLimitRecipientTransferAction>) {
    yield* put(setDFAOwnershipTransferLoading(true))
    try {
        const { error: buyerTypeError, data: buyerTypeData } = yield* call(
            api.lib.getProfileTypeByUserService,
            payload,
        )
        if (buyerTypeError || buyerTypeData?.error) {
            throw buyerTypeError || buyerTypeData?.error
        }
        if (buyerTypeData?.items) {
            yield* put(
                dfaOwnershipTransferSlice.actions.setBuyerDataTransfer({
                    type: buyerTypeData.items?.[0]?.type,
                }),
            )
        }
        const { data: qualifyData, error: qualifyError } = yield* call(
            api.lib.getQualifyInfo,
            payload,
        )
        if (qualifyError || qualifyData?.error) {
            throw qualifyError || qualifyData?.error
        }
        if (qualifyData?.item) {
            yield* put(
                dfaOwnershipTransferSlice.actions.setBuyerDataTransfer({
                    is_qualified: qualifyData.item?.is_qualified,
                    quota: qualifyData.item?.quota,
                }),
            )
        }
    } catch (error) {
        yield* put(setDFAOwnershipTransferError(error))
        openMessage({
            type: 'error',
            message: 'Не удалось проверить лимит Акцептанта',
        })
    }
}

function* transferCancelByOwnerHandlerPrefly({ payload }: ReturnType<typeof transferCancelByOwner>) {
    try {
        openMessage({
          type: 'loading',
          key: 'prefly',
          message: 'Формирование транзакции',
        });
      
        yield * put(setIsLoadingTransactionDetails(true));
        const { data, error } = yield* call(api.lib.transferCancelByOwnerServiceV2, payload)

        if (error || data?.error) {
            throw error ?? data?.error
        }
        yield * put(setItemsTransactionDetails(data));
        closeMessage('prefly');
        yield * put(setIsLoadingTransactionDetails(false));
      } catch (error) {
        yield * put(setIsLoadingTransactionDetails(false));
        closeMessage('prefly');
        openMessage({
          type: 'error',
          message: 'Ошибка формирования транзакции',
        });
      }
}

function* transferCancelByOwnerHandler({ payload }: ReturnType<typeof transferCancelByOwner>) {
    try {
        yield* put(setDFAOwnershipTransferLoading(true))

        const data = yield* select(transactionDetailsSelectors.selectItems)

        if (data?.item) {
            yield* signData(data?.item?.status, payload.skid, data)
            openMessage({
                type: 'success',
                message: 'Запрос на отзыв оферты успешно отправлен',
            })
            yield* put(clearDataTransactionDetails())

        }
    } catch (error) {
        yield* put(clearDataTransactionDetails())
        yield* put(setDFAOwnershipTransferError(error))
        openMessage({
            type: 'error',
            message: 'Возникли ошибки при отзыве оферты',
        })
    } finally {
        yield* put(setDFAOwnershipTransferLoading(true))
    }
}

function* transferCancelByRecipientHandler(action: ReturnType<typeof getDFAOwnershipTransfer>) {
    yield* put(setDFAOwnershipTransferLoading(true))

    try {
        const { data, error } = yield api.lib.transferCancelByRecipientService(action)
        if (error) {
            throw new Error(error)
        }
        if (data?.item) {
            yield* put(setDFAOwnershipTransfer({ data: data.item }))
        }
    } catch (error) {
        yield* put(setDFAOwnershipTransferError(error))
    }
}

function* executeDFATransferServiceV2Prefly(action: ReturnType<typeof acceptDFAOwnershipTransfer>) {
    try {
        openMessage({
          type: 'loading',
          key: 'prefly',
          message: 'Формирование транзакции',
        });
      
        yield * put(setIsLoadingTransactionDetails(true));
        const { data, error } = yield api.lib.executeDFATransferServiceV2(action.payload)

        if (error || data?.error) {
            throw error || data?.error
        }
        yield * put(setItemsTransactionDetails(data));
        closeMessage('prefly');
        yield * put(setIsLoadingTransactionDetails(false));
      } catch (error) {
        yield * put(setIsLoadingTransactionDetails(false));
        closeMessage('prefly');
        openMessage({
          type: 'error',
          message: 'Ошибка формирования транзакции',
        });
      }
}

function* executeDFATransferServiceV2(action: ReturnType<typeof acceptDFAOwnershipTransfer>) {
    try {
        yield* put(setDFAOwnershipTransferLoading(true))
        
        const data = yield* select(transactionDetailsSelectors.selectItems)

        if (data?.item) {
            yield* signData(data?.item?.status, action.payload.skid, data)
            openMessage({
                type: 'success',
                message: 'Запрос на исполнение оферты успешно отправлен',
            })
            yield* put(clearDataTransactionDetails())

        }
    } catch (error) {
        yield* put(clearDataTransactionDetails())
        yield* put(setDFAOwnershipTransferError(error))
        openMessage({
            type: 'error',
            message: `Возникли ошибки при исполнении оферты${
                isAxiosError(error) ? `: ${errorTranslates[error.response.data.error.code]}` : ''
            }`,
        })
    } finally {
        yield* put(setDFAOwnershipTransferLoading(false))
    }
}

function* loadOfferentDFA({ payload }: ReturnType<typeof getOfferentDFA>) {
    try {
        const uuid = yield* select(authSelector.selectUserUid)
        const dfa = yield* call(getDfaByIdHelper, payload)

        if (!dfa) {
            throw new Error()
        }

        const balancePayload = {
            uuid,
            dfaId: Number(dfa.id),
            withPayoffAgreements: true,
            isInvestorRepaymentCheck: true,
        }

        const balance = yield* call(getDfaBalanceByIdHelper, balancePayload)

        dfa.balance = balance

        yield* put(setOfferentOffer({ dfa }))
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникли ошибки при получении оферты',
        })
    }
}

function* loadAcceptorDFA({ payload }: ReturnType<typeof getAcceptorDFA>) {
    try {
        const uuid = yield* select(authSelector.selectUserUid)
        const dfa = yield* call(getDfaByIdHelper, payload)

        if (!dfa) {
            throw new Error()
        }

        const balancePayload = {
            uuid,
            dfaId: Number(dfa.id),
            withPayoffAgreements: true,
            isInvestorRepaymentCheck: true,
        }

        const balance = yield* call(getDfaBalanceByIdHelper, balancePayload)

        dfa.balance = balance

        yield* put(setAccepterOffer({ dfa }))
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникли ошибки при получении оферты',
        })
    }
}

function* handleLoadTransferDeals() {
    try {
        const uuid = yield* select(authSelector.selectUserUid)
        const { global_id } = yield* select(dfaTransferSelector.selectData)
        yield* put(setTransferDealsIsLoading(true))

        const { data, error } = yield api.lib.getTransferDfaDeals({ user_id: uuid })

        const items = data?.items
            ?.filter((item) => {
                return item.order.global_id === global_id
            })
            .map((item) => {
                return {
                    ...item,
                    asset_ticker_symbol: item.order?.seller_asset_data?.asset_ticker_symbol,
                    seller_full_name: item.order?.seller_full_name,
                    buyer_full_name: item.order?.buyer_full_name,
                    amount_dfa: item.order?.seller_asset_data?.amount_dfa,
                }
            })

        if (error) {
            throw error
        }

        yield* put(setTransferDeals(items ?? []))
    } catch (error) {
        yield* put(setTransferDeals([]))
    } finally {
        yield* put(setTransferDealsIsLoading(false))
    }
}

function* handleLoadTransferFee({ payload }: ReturnType<typeof getTransferFee>) {
    try {
        const data = yield* call(getCalculateFeeHelper, payload)

        yield* put(setFee(data.item.FeeAmount))
    } catch (error) {
        yield* put(setFee(undefined))
    }
}

export function* watchDFAOwnershipTransferRequest(): Generator<StrictEffect> {
    yield* takeLatest(getDFAOwnershipTransfer.type, loadDFAOwnershipTransfer)
    yield* takeLatest(acceptDFAOwnershipTransfer.type, acceptOwnershipTransfer)
    yield* takeLatest(acceptDFAOwnershipTransferPrefly.type, acceptOwnershipTransferPrefly)
    yield* takeLatest(
        createDFAOwnershipTransferRequestActionPrefly.type,
        createDFAOwnershipTransferRequestPrefly,
    )
    yield* takeLatest(
        createDFAOwnershipTransferRequestAction.type,
        createDFAOwnershipTransferRequest,
    )
    yield* takeLatest(checkLimitRecipientTransferAction.type, checkLimitRecipientTransfer)
    yield* takeLatest(transferCancelByOwner.type, transferCancelByOwnerHandler)
    yield* takeLatest(transferCancelByOwnerPrefly.type, transferCancelByOwnerHandlerPrefly)
    yield* takeLatest(transferCancelByRecipient.type, transferCancelByRecipientHandler)
    yield* takeLatest(executeDFATransfer.type, executeDFATransferServiceV2)
    yield* takeLatest(executeDFATransferPrefly.type, executeDFATransferServiceV2Prefly)


    yield* takeLatest(getOfferentDFA.type, loadOfferentDFA)
    yield* takeLatest(getAcceptorDFA.type, loadAcceptorDFA)
    yield* takeLatest(getTransferDeals.type, handleLoadTransferDeals)
    yield* takeLatest(getTransferFee.type, handleLoadTransferFee)
}
