import {
    IssueAvailableInvestorsType,
    ProfileType,
    TransferOrderStatus,
    stringToNumber,
} from '@dltru/dfa-models'
import { createSelector } from 'reselect'
import IAppState from 'store/states'

import { authSelector } from '@store/auth/selectors'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import { profileSelector } from '@store/profiles/details/selectors'

const selectData = (state: IAppState) => state.dfaOwnershipTransfer.data
const selectIsLoading = (state: IAppState) => state.dfaOwnershipTransfer.isLoading
const selectDealsIsLoading = (state: IAppState) => state.dfaOwnershipTransfer.dealsIsLoading
const selectBuyerData = (state: IAppState) => state.dfaOwnershipTransfer.buyerData
const selectOfferentOffer = (state: IAppState) => state.dfaOwnershipTransfer.offerentOffer
const selectAcceptorOffer = (state: IAppState) => state.dfaOwnershipTransfer.accepterOffer
const selectTransferDeals = (state: IAppState) => state.dfaOwnershipTransfer.deals
const selectFee = (state: IAppState) => state.dfaOwnershipTransfer.fee

const isDfaSelected = createSelector(
    [selectOfferentOffer, selectAcceptorOffer],
    (offerentOffer, accepterOffer) => {
        return offerentOffer?.dfa.id || accepterOffer?.dfa.id
    },
)

const selectOfferByType = createSelector(
    [selectOfferentOffer, selectAcceptorOffer, (_, type: 'acceptor' | 'offerent') => type],
    (offerentOffer, accepterOffer, type) => {
        return type === 'acceptor' ? accepterOffer : offerentOffer
    },
)

const isMyAcceptedTransfer = createSelector(
    selectData,
    authSelector.selectUserUid,
    (order, userUid) => {
        if (order?.is_public) {
            return order?.status === TransferOrderStatus.placed && order?.seller_id !== userUid
        }
        return order?.status === TransferOrderStatus.placed && order?.buyer_id === userUid
    },
)

const isMyTransfer = createSelector(selectData, authSelector.selectUserUid, (order, userUid) => {
    return order?.seller_id === userUid || order?.seller_id_button === userUid
})

const showLimitAlert = createSelector(
    selectData,
    authSelector.selectUserUid,
    dfaDetailsSelector.selectDfaDetails,
    profileSelector.isCheckQuota,
    profileSelector.selectQualifyInfo,
    selectBuyerData,
    (order, userUid, dfa, isCheckQuota, qualifyInfo, buyerData) => {
        return (
            ((order?.status === TransferOrderStatus.accepted ||
                order?.status === TransferOrderStatus.placed) &&
                order?.buyer_id === userUid &&
                isCheckQuota &&
                Number.isInteger(qualifyInfo?.quota) &&
                Number.isInteger(order?.total_price) &&
                Number(qualifyInfo?.quota) < Number(order?.total_price) &&
                dfa?.issue_available_investors_type_limits ===
                    IssueAvailableInvestorsType.qualified_and_unqualified_with_limit) ||
            (order?.status === TransferOrderStatus.accepted &&
                dfa?.issue_available_investors_type_limits ===
                    IssueAvailableInvestorsType.qualified_and_unqualified_with_limit &&
                buyerData.is_qualified === false &&
                buyerData.type === ProfileType.PRSN &&
                order?.seller_id === userUid &&
                Number.isInteger(order?.total_price) &&
                buyerData.quota < Number(order?.total_price))
        )
    },
)
const showQualAlert = createSelector(
    selectData,
    authSelector.selectUserUid,
    dfaDetailsSelector.selectDfaDetails,
    profileSelector.selectQualifyInfo,
    selectBuyerData,
    (order, userUid, dfa, qualifyInfo, buyerData) => {
        return (
            ((order?.status === TransferOrderStatus.accepted ||
                order?.status === TransferOrderStatus.placed) &&
                order?.buyer_id === userUid &&
                qualifyInfo?.is_qualified === false &&
                dfa?.issue_available_investors_type === IssueAvailableInvestorsType.qualified) ||
            (order?.status === TransferOrderStatus.accepted &&
                order?.seller_id === userUid &&
                dfa?.issue_available_investors_type === IssueAvailableInvestorsType.qualified &&
                buyerData.is_qualified === false)
        )
    },
)

const selecAllAmountOfferent = createSelector(selectOfferentOffer, (currentOffer) => {
    if (currentOffer.amount_dfa && currentOffer.dfa && currentOffer.dfa.price_per_dfa) {
        let operation_amount =
            stringToNumber(currentOffer.amount_dfa) * currentOffer.dfa?.price_per_dfa * 100
        operation_amount = currentOffer.price
            ? operation_amount + currentOffer.price
            : operation_amount

        return operation_amount
    }

    if (currentOffer.price) {
        return currentOffer.price
    }
})

const selecAllAmountAcceptant = createSelector(selectAcceptorOffer, (currentOffer) => {
    if (currentOffer.amount_dfa && currentOffer.dfa && currentOffer.dfa.price_per_dfa) {
        let operation_amount =
            stringToNumber(currentOffer.amount_dfa) * currentOffer.dfa?.price_per_dfa * 100
        operation_amount = currentOffer.price
            ? operation_amount + currentOffer.price
            : operation_amount

        return operation_amount
    }

    if (currentOffer.price) {
        return Number(`${currentOffer.price}`.replaceAll(' ', ''))
    }
})

const selecAcceptantFee = createSelector(selectData, (data) => {
    return data?.accept_fee_amount
})

export const dfaTransferSelector = {
    selectData,
    selectIsLoading,
    isMyAcceptedTransfer,
    isMyTransfer,
    showLimitAlert,
    showQualAlert,
    selectOfferentOffer,
    selectAcceptorOffer,
    selectOfferByType,
    selectTransferDeals,
    isDfaSelected,
    selectDealsIsLoading,
    selectFee,
    selecAllAmountOfferent,
    selecAllAmountAcceptant,
    selecAcceptantFee,
}
